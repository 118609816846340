import React, { useCallback, useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useSpring, animated } from 'react-spring';
import useScrollCallback from '@/hooks/useScrollCallback';

const cities = ['Paris', 'Lyon', 'Bordeaux', 'Bayonne', 'Where you are'];
const rotates = [
  '-rotate-[100deg]',
  '-rotate-[120deg]',
  '-rotate-[150deg]',
  '-rotate-[165deg]',
  '-rotate-[200deg]',
];

const Location = () => {
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>();
  const [pos, setPos] = useState(0);
  const itemsRef = useRef([]);

  const data = useStaticQuery(graphql`
    query {
      location: file(relativePath: { eq: "location.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  const styles = useSpring({
    to: [
      { transform: 'scale(0.9) rotate(10deg)' },
      { transform: 'scale(1.1) rotate(-10deg)' },
      { transform: 'scale(0.95) rotate(5deg)' },
      { transform: 'scale(1.05) rotate(-5deg)' },
      { transform: 'scale(1) rotate(0deg)' },
    ],
    from: { transform: 'scale(1) rotate(0deg)' },
    config: {
      duration: 100,
    },
  });

  useEffect(() => {
    styles.transform.start();
  }, [pos]);

  const handleScroll = useCallback(() => {
    const { y, height } = containerRef.current.getBoundingClientRect();
    const thresholdMin = height * 1.6; // Start animation when scrolling at 0.6 height above element
    const thresholdMax = height * 0.5; // Finish animation when scrolling at half the element
    const currentAdvancementPercentage =
      (thresholdMin - y) / (thresholdMin - thresholdMax);

    if (currentAdvancementPercentage < 0) {
      setPos(0);
    } else if (currentAdvancementPercentage <= 1) {
      setPos(Math.floor(currentAdvancementPercentage * cities.length));
    }
  }, [cities, setPos]);

  useScrollCallback(handleScroll);

  const handleMouseEnter = useCallback(
    (i) => {
      setPos(i);
    },
    [setPos],
  );

  return (
    <div>
      <div
        className="flex gap-10 justify-between items-center lg:pr-[20%]"
        ref={containerRef}
      >
        <div className="font-heading text-4xl lg:text-[86px] leading-none">
          {cities.map((city, i) => {
            if (i >= cities.length - 1) return null; // Don't display the "where you are", but still keep it in array for animation calculations
            return (
              <div
                key={city}
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={handleScroll}
                ref={(el) => {
                  itemsRef.current[i] = el;
                }}
                className={`transition-colors mb-4 ${
                  pos === i ? 'text-primary' : ''
                }`}
              >
                {city}
              </div>
            );
          })}
        </div>
        <animated.div style={styles}>
          <div
            className={classNames(
              'w-[250px] transition-all duration-500 ease-[cubic-bezier(.47,1.64,.8,.8);]',
              rotates[pos],
            )}
          >
            <GatsbyImage image={getImage(data.location)} alt="location" />
          </div>
        </animated.div>
      </div>
      <h2
        className="mt-12"
        onMouseEnter={() => handleMouseEnter(4)}
        onMouseLeave={handleScroll}
      >
        {t('locationWeAre')}
        <span
          className={`transition-colors ${
            pos === cities.length - 1 ? 'text-primary' : ''
          }`}
        >
          {t('locationWhereYouAre')}
        </span>
      </h2>
      <p className="subcontent mt-12">{t('locationContent')}</p>
    </div>
  );
};

export default Location;
