import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import LogoLink from '@/assets/svg/logo-linkedin.svg';

import TitleText from '@/components/TitleText';

interface OurTeamProps {
  className?: string;
}

const members = [
  {
    name: 'Nicolas Bertrand',
    role: 'CEO',
    linkedin: 'https://www.linkedin.com/in/nicolas-bertrand-40650454/',
    imageKey: 'nicolas',
  },
  {
    name: 'Jordi Alerany Canut',
    role: 'Lead Data Scientist',
    linkedin: 'https://www.linkedin.com/in/jalerany/',
    imageKey: 'jordi',
  },
  {
    name: 'Alexandre Rio',
    role: 'Lead Developer',
    linkedin: 'https://www.linkedin.com/in/alexandre-rio-52754b65/',
    imageKey: 'alexandre',
  },
  {
    name: 'Simon Robert',
    role: 'Lead Developer',
    linkedin: 'https://www.linkedin.com/in/simonrobert1/',
    imageKey: 'simon',
  },
  {
    name: 'Raphaël Didier',
    role: 'Lead Developer',
    linkedin: 'https://www.linkedin.com/in/rapha%C3%ABl-didier/',
    imageKey: 'raphael',
  },
  {
    name: 'Clément Lemaire',
    role: 'Lead Growth & OP',
    linkedin: 'https://www.linkedin.com/in/cl%C3%A9mentlemaire/',
    imageKey: 'clement',
  },
  {
    name: 'Guillaume Bertrand',
    role: 'Product Manager',
    linkedin: 'https://www.linkedin.com/in/guillaumebertrand/',
    imageKey: 'guillaume',
  },
];

const OurTeam: React.FC<OurTeamProps> = ({ className }) => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      alexandre: file(relativePath: { eq: "team/alexandre-rio.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      clement: file(relativePath: { eq: "team/clement-lemaire.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      guillaume: file(relativePath: { eq: "team/guillaume-bertrand.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      jordi: file(relativePath: { eq: "team/jordi-alerany-canut.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      nicolas: file(relativePath: { eq: "team/nicolas-bertrand.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      raphael: file(relativePath: { eq: "team/raphael-didier.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      simon: file(relativePath: { eq: "team/simon-robert.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <div className={classNames(className)}>
      <TitleText title={t('ourTeamTitle')} />
      <div className="subcontent">{t('ourTeamContent')}</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-x-5 lg:gap-x-12 gap-y-12 lg:gap-y-[100px] mt-12 font-heading">
        {members.map(({ name, role, linkedin, imageKey }, i) => (
          <div
            className="text-center lg:text-left text-xl flex flex-col gap-3"
            key={i}
          >
            <GatsbyImage
              image={getImage(data[imageKey])}
              alt="Us"
              className="w-[250px] lg:w-full rounded-main aspect-square mb-4 mx-auto"
              objectFit="cover"
            />
            <div>{name}</div>
            <div className="text-primary">{role}</div>
            <a
              href={linkedin}
              target="_blank"
              rel="noreferrer"
              className="w-fit mt-2 mx-auto lg:mx-0"
            >
              <LogoLink
                width={24}
                className="fill-mainDark dark:fill-white hover:fill-primary hover:dark:fill-primaryLighter hover:-translate-y-[3%] transition-all"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurTeam;
