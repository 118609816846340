import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import TitleText from '@/components/TitleText';

interface CleanWorkProps {
  className?: string;
}

const CleanWork: React.FC<CleanWorkProps> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(className)}>
      <TitleText title={t('cleanWorkTitle')} />
      <div className="subcontent">{t('cleanWorkContent')}</div>
    </div>
  );
};

export default CleanWork;
