import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import TitleText from '@/components/TitleText';

interface WeKowProps {
  className?: string;
}

const WeKow: React.FC<WeKowProps> = ({ className }) => {
  const { t } = useTranslation();
  const [embedVideo, setEmbedVideo] = useState(false);
  const isMobile = typeof window === 'undefined' || window.innerWidth < 1044;

  const data = useStaticQuery(graphql`
    query {
      WeKow: file(relativePath: { eq: "team.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      play: file(relativePath: { eq: "play.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      frenchWeb: file(relativePath: { eq: "frenchweb.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <div className={classNames(className)}>
      <GatsbyImage
        image={getImage(data.WeKow)}
        alt="Clean work"
        className="w-full rounded-main"
      />
      <TitleText title={t('weKnowTitle')} />
      <div className="children:mb-[30px] subcontent mb-[120px]">
        {t('weKnowContent')}
      </div>

      {/* Frenchweb */}
      {!embedVideo && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          onClick={() => setEmbedVideo(true)}
          className="block relative imageUplift"
        >
          <GatsbyImage
            image={getImage(data.frenchWeb)}
            alt="Frenchweb interview"
            className="w-full rounded-main"
          />
          <div className="absolute inset-0 m-auto lg:w-[270px] lg:h-[270px] w-[100px] h-[100px]">
            <GatsbyImage
              image={getImage(data.play)}
              alt="play"
              className="w-full"
            />
          </div>
        </div>
      )}
      {embedVideo && (
        <iframe
          width="100%"
          height={isMobile ? 200 : 600}
          src="https://www.youtube.com/embed/L0Q8paBuFsk"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
      <div className="text-center text-xs lg:text-sm mt-6">
        {t('frenchWebInterview')}
      </div>
    </div>
  );
};

export default WeKow;
