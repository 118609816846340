import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { getImage } from 'gatsby-plugin-image';

import { tradWithColors } from '@/utils/trad';
import { CONTACT } from '@/referentials/routes';
import { workMail } from '@/referentials/infos';

import Layout from '@/components/Layout';
import CleanWork from '@/components/About/CleanWork';
import WeKnow from '@/components/About/WeKnow';
import OurTeam from '@/components/About/OurTeam';
import Location from '@/components/About/Location';
import CtaBlock from '@/components/CtaBlock';
import SubContent from '@/components/SubContent';

const dividerClasses = 'mb-[110px] lg:mb-[220px]';

export default function About({ data }) {
  const { t } = useTranslation();

  return (
    <Layout description={t('description')} withIcons>
      <div className="relative h-screen w-full flex flex-col justify-center items-center max-w-[780px] m-auto text-center">
        <h1 className="lg:leading-[96px] lg:tracking-[2px]">
          {tradWithColors(t('title'))}
        </h1>
        <div className="text-xl mt-12 leading-normal">{t('subTitle')}</div>
      </div>
      <SubContent>
        <WeKnow className={dividerClasses} />
        <CleanWork className={dividerClasses} />
        <OurTeam className={dividerClasses} />
        <Location />
      </SubContent>
      <div className="relative">
        <CtaBlock
          external
          className="my-[50px] lg:my-[100px] !bg-primaryExtraLight dark:!bg-primaryExtraLight !text-mainDark"
          title={t('ctaBlockTitle1')}
          to={`mailto:${workMail}`}
          linkTitle={t('ctaBlockLink1')}
          emoji={{
            image: getImage(data.add),
            alt: 'add icon',
          }}
        />
        <CtaBlock
          title={t('ctaBlockTitle2')}
          to={CONTACT}
          linkTitle={t('ctaBlockLink2')}
          emoji={{
            image: getImage(data.chat),
            alt: 'chat icon',
          }}
        />
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["about", "general"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    chat: file(relativePath: { eq: "chat.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, placeholder: BLURRED)
      }
    }
    add: file(relativePath: { eq: "add.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, placeholder: BLURRED)
      }
    }
  }
`;
