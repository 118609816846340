import classNames from 'classnames';
import React from 'react';

interface TitleTextProps {
  title: string | React.ReactNode;
  className?: string;
}

const TitleText: React.FC<TitleTextProps> = ({ title, className }) => {
  return (
    <div className={classNames('mt-[80px] mb-[40px]', className)}>
      <h2 className="text-4xl lg:leading-[80px] lg:text-6xl pb-5 lg:pb-10 w-10/12">
        {title}
      </h2>
    </div>
  );
};

export default TitleText;
