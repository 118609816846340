import { useEffect } from 'react';

export default function useScrollCallback(cb) {
  useEffect(() => {
    cb();
    window?.addEventListener('scroll', cb);
    return () => {
      window?.removeEventListener('scroll', cb);
    };
  }, []);
}
