import classNames from 'classnames';
import React from 'react';

interface SubContentProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

const SubContent: React.FC<SubContentProps> = ({
  children,
  className = '',
}) => {
  return (
    <div
      className={classNames(
        className,
        'lg:bg-white lg:shadow-subcontentWhite z-30 relative mt-[100px] mb-[100px] rounded-main text-mainDark lg:mb-[200px]',
        'lg:dark:bg-mainDark lg:dark:shadow-cbig dark:text-white',
      )}
    >
      <div className="2xl:py-[120px] lg:py-[90px] md:max-w-mdMain xl:max-w-main mx-auto">
        {children}
      </div>
    </div>
  );
};

export default SubContent;
